import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Beliefs from '../../Our_Beliefs.pdf'
import DNA from '../../Our_DNA.pdf'
import ChildSafe from '../../Child_Safe_Policies.pdf'

class AboutUs extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const { data } = this.props
    return (
      <Layout>
        <Helmet>
          <title>About - Gracehouse Church Logan</title>
          <meta property='og:title' content='About - Gracehouse Church Logan' />
          <meta
            name='description'
            content='We are a Christian Church led by pastor Patrick McConnell. Read our core beliefs.'
          />
          <meta
            property='og:description'
            content='We are a Christian Church led by pastor Patrick McConnell. Read our core beliefs.'
          />
        </Helmet>
        <section className='updates-header'>
          <div className='container'>
            <h1>About Us</h1>
          </div>
        </section>
        <section className='generic-body rich-text-body'>
          <div className='container'>
            <div className='generic-rich-text'>
              <div className='pastoral-section'>
                <h2 className='h1'>Overseer</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.patrickHeadshot.childImageSharp.fluid} />
                    <p>Patrick McConnell</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Operations Pastor</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.felipeHeadshot.childImageSharp.fluid} />
                    <p>Felipe Borja</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Pastors</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.annaHeadshot.childImageSharp.fluid} />
                    <p>Anna McConnell</p>
                  </div>
                  <div className='pastor'>
                    <Img fluid={data.colinHeadshot.childImageSharp.fluid} />
                    <p>Colin Stoodley</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Committee of Management</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.mattHeadshot.childImageSharp.fluid} />
                    <p>Matthew Hubner</p>
                  </div>
                  <div className='pastor'>
                    <Img fluid={data.benHeadshot.childImageSharp.fluid} />
                    <p>Ben Smith</p>
                  </div>
                  <div className='pastor'>
                    <Img fluid={data.felipeHeadshot.childImageSharp.fluid} />
                    <p>Felipe Borja</p>
                  </div>
                  <div className='pastor'>
                    <Img fluid={data.patrickHeadshot.childImageSharp.fluid} />
                    <p>Patrick McConnell</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Worship Coordinator</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.felipeHeadshot.childImageSharp.fluid} />
                    <p>Felipe Borja</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Prayer Team Coordinator</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.laurenHeadshot.childImageSharp.fluid} />
                    <p>Lauren Palma</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Setup Team Coordinator</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.robHeadshot.childImageSharp.fluid} />
                    <p>Rob Gawler</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Media Coordinator</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.ruanHeadshot.childImageSharp.fluid} />
                    <p>Ruan Strydom</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Gracekids Coordinator</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.adeleHeadshot.childImageSharp.fluid} />
                    <p>Adele Strydom</p>
                  </div>
                </div>
              </div>

              <div className='pastoral-section'>
                <h2 className='h1'>Hospitality Coordinator</h2>
                <div className='pastoral-team'>
                  <div className='pastor'>
                    <Img fluid={data.wendyHeadshot.childImageSharp.fluid} />
                    <p>Wendy Pretorius</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container'>
            <h2 className='h1'>Downloads</h2>
            <div className='downloads-container'>
              <a href={DNA} target='_blank' className='default-button'>
                DNA Document
              </a>
              <a href={Beliefs} target='_blank' className='default-button'>
                Statement of Beliefs
              </a>
              <a href={ChildSafe} target='_blank' className='default-button'>
                Child Safety Policies
              </a>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default AboutUs

export const pageQuery = graphql`
  query AboutUsQuery {
    site {
      siteMetadata {
        title
      }
    }
    genericHeadshot: file(relativePath: { eq: "headshots/generic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patrickHeadshot: file(relativePath: { eq: "headshots/patrick.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theunisHeadshot: file(relativePath: { eq: "headshots/theunis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    felipeHeadshot: file(relativePath: { eq: "headshots/felipe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wendyHeadshot: file(relativePath: { eq: "headshots/wendy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ruanHeadshot: file(relativePath: { eq: "headshots/ruan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    alanHeadshot: file(relativePath: { eq: "headshots/alan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    colinHeadshot: file(relativePath: { eq: "headshots/colin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    juanitaHeadshot: file(relativePath: { eq: "headshots/juanita.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laurenHeadshot: file(relativePath: { eq: "headshots/lauren.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    annaHeadshot: file(relativePath: { eq: "headshots/anna.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    robHeadshot: file(relativePath: { eq: "headshots/rob.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adeleHeadshot: file(relativePath: { eq: "headshots/adele.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    robynHeadshot: file(relativePath: { eq: "headshots/robyn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    benHeadshot: file(relativePath: { eq: "headshots/ben.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hannahHeadshot: file(relativePath: { eq: "headshots/hannah.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mattHeadshot: file(relativePath: { eq: "headshots/matt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timHeadshot: file(relativePath: { eq: "headshots/tim.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
